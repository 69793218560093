import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import '@isceco/widget-library2/basic-elements/Filter/FilterGroup.js'
import '@isceco/widget-library2/basic-elements/Filter/StringFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/DateFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/MultiselectFilter.js'
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import '@isceco/widget-library2/basic-elements/Link/Link.js'
import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'
import DatenfileColumns from './DatenfileColumns.js'
import MetadatenService from './MetadatenService.js'

export default class Datenfile extends WebComponent {

  constructor() {
    super()
  }

  get translationFile() {
    return './views/Datenfile/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.metadatenService = new MetadatenService()
    this.tableEvent = 'metadaten-changed'
    this.loeschenId = 'datenfile-loeschen-dialog'
    this.dateiId = 'datenfile-datei-dialog'
    this.reload()
  }

  reload() {
    this.metadatenService.getBackendListUrl()
      .then(url => {
        this.url = url
        this.render()
      })
      .catch(_ => {
        this.render()
      })
  }

  getTemplate() {
    const columns = [
      DatenfileColumns.NAME_COLUMN({callback: row => this.openDateiDialog(row)}),
      DatenfileColumns.CHANGED_COLUMN(),
      DatenfileColumns.VALID_COLUMN(),
      DatenfileColumns.DELETE_COLUMN({callback: row => this.openLoeschenDialog(row)})
    ]
    return html`
      <isceco-title id="page-title"
                    text="${this.i18n.translate('datenfile.titel')}"
      ></isceco-title>

      <p>${this.i18n.translate('datenfile.beschreibung')}</p>

      <isceco-button variant="primary"
                     text="${this.i18n.translate('datenfile.erstellen')}"
                     @click="${this.newFile}"
      ></isceco-button>

      <isceco-filter-group style="margin-top: var(--isceco-space-300)">
        <isceco-string-filter label="${this.i18n.translate('table.header.name')}"
                              key="name"
                              minchars="1"
        ></isceco-string-filter>
        <isceco-date-filter label="${this.i18n.translate('table.header.changed')}"
                            key="changed"
        ></isceco-date-filter>
        <isceco-multiselect-filter label="${this.i18n.translate('table.header.valid')}"
                                   key="valid"
                                   .i18n="${this.i18n}"
                                   .items="${[
                                     {value: true, name: 'valid.true'},
                                     {value: false, name: 'valid.false'}
                                   ]}"
        ></isceco-multiselect-filter>
      </isceco-filter-group>

      <isceco-list id="flamfrontend-organisation"
                   update-event="${this.tableEvent}"
                   url="${this.url}"
                   .i18n="${this.i18n}"
                   .columns="${columns}"
                   sort="changed"
      ></isceco-list>

      <isceco-dialog hidden
                     id="${this.loeschenId}"
                     header="${this.i18n.translate('datenfile.loeschen.dialog.title')}"
                     description="${this.i18n.translate('datenfile.loeschen.dialog.description')}"
                     confirm-button="${this.i18n.translate('datenfile.loeschen.dialog.button.ok')}"
                     cancel-button="${this.i18n.translate('datenfile.loeschen.dialog.button.cancel')}"
                     @submit="${e => this.loeschen(e)}"
      ></isceco-dialog>

      <isceco-dialog hidden
                     id="${this.dateiId}"
                     header="${this.i18n.translate('datenfile.datei.dialog.title')}"
                     @submit="${e => e.target.hidden = true}"
      >
        <isceco-link text="${this.i18n.translate('datenfile.datei.dialog.view')}"
                     @click="${_ => this.toDetail()}"
        ></isceco-link>
        </br></br>
        <isceco-link text="${this.i18n.translate('datenfile.datei.dialog.download')}"
                     @click="${_ => this.download()}"
        ></isceco-link>
      </isceco-dialog>
    `
  }

  openLoeschenDialog = metadaten => {
    this.data = metadaten
    this.querySelector(`#${this.loeschenId}`).hidden = false
  }

  loeschen = event => {
    if (event.detail.confirmed) {
      this.metadatenService.delete(this.data.id)
        .then(_ => {
          showAlert(
            this.i18n.translate('metadaten.success.titel.loeschen'),
            this.i18n.translate('metadaten.success.body.loeschen', {name: this.data.name}),
            'success'
          )
          send(this.tableEvent)
        })
    }
    event.target.hidden = true
  }

  openDateiDialog = metadaten => {
    this.data = metadaten
    this.querySelector(`#${this.dateiId}`).hidden = false
  }

  toDetail = () => {
    navigate({to: 'DatenfileDetail', params: {id: this.data.id}})
  }

  download = () => {
    this.metadatenService.read(this.data.id)
      .then(json => {
        download(toValidFilename(json.name, 'json'), json.content)
      })
      .finally(_ => {
        this.querySelector(`#${this.dateiId}`).hidden = true
      })
  }

  newFile = () => {
    navigate({to: 'DatenfileDetail'})
  }
}

customElements.define('flam-datenfile', Datenfile)
