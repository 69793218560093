import Render from './Render.js'
import {html} from '@isceco/widget-library2/external/lit'
import Enum from '../Enum/Enum.js'

export default class EmployeeRender extends Render {
  get employees() {
    return this.metadaten.Employees.Employee
  }

  render = () => html`
    <div class="group">
      <div class="header row">
        ${this.i18n.translate('label.employees')}
      </div>
      ${this.employees.map((_, index) => this.renderMitarbeiter(parseInt(index)))}
      <div class="edit">
        <isceco-button icon="plus"
                       text="${this.i18n.translate('label.employees.add')}"
                       variant="tertiary"
                       @click="${_ => this.addEmployee()}"
        ></isceco-button>
      </div>
    </div>
  `

  renderMitarbeiter = index => {
    const buildKey = path => `Employees.Employee.[${index}].${path}`
    const exclude = `[${index}].`
    return html`
      <details ?open="${!this.read}" id="employee-row-${index}">
        <summary class="subheader row">
          ${this.i18n.translate('label.employees.employee', {index: index + 1})}
          <div class="edit">
            <isceco-button icon="x"
                           text="${this.i18n.translate('label.employees.remove')}"
                           variant="tertiary"
                           @click="${_ => this.removeEmployee(index)}"
            ></isceco-button>
          </div>
        </summary>
        <div class="section">
          <div class="collapsible row" @click="${e => this.rotatePriority(e)}">
            ${this.translate('employees.employee.employeeinfo')}
          </div>
          ${this.text({exclude: exclude, key: buildKey('EmployeeInfo.Lastname'), maxLength: 100, priority: true, required: true})}
          ${this.text({exclude: exclude, key: buildKey('EmployeeInfo.Firstname'), maxLength: 100, priority: true, required: true})}
          ${this.date({exclude: exclude, key: buildKey('EmployeeInfo.DateOfBirth'), priority: true, required: true})}
          ${this.select({exclude: exclude, key: buildKey('EmployeeInfo.Nationality'), items: Enum.country(), priority: true, required: true})}
          ${this.select({exclude: exclude, key: buildKey('EmployeeInfo.Gender'), items: Enum.gender(), priority: true, required: true})}
        </div>
        <div class="section">
          <div class="collapsible row" @click="${e => this.rotatePriority(e)}">
            ${this.translate('employees.employee.zemis', {index: index + 1})}
          </div>
          ${this.number({exclude: exclude, key: buildKey('Zemis.Zemisnr'), max: 999999999, priority: true})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.Lastname'), maxLength: 75, priority: true})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.Firstname'), maxLength: 50, priority: true})}
          ${this.date({exclude: exclude, key: buildKey('Zemis.DateOfBirth'), maxLength: 100, priority: true})}
          ${this.select({exclude: exclude, key: buildKey('Zemis.Nationality'), items: Enum.country()})}
          ${this.select({exclude: exclude, key: buildKey('Zemis.Gender'), items: Enum.gender()})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.ForeignSocialsecurityNr'), maxLength: 25})}
          ${this.date({exclude: exclude, key: buildKey('Zemis.Egeftaregulationdate')})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.CantonalReferenceNr'), maxLength: 13})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.CompanyName'), maxLength: 32, priority: true})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.CompanyNameAddon'), maxLength: 32})}
          ${this.select({exclude: exclude, key: buildKey('Zemis.BranchOfEconomy'), items: Enum.branch(), priority: true})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.Street'), maxLength: 25, priority: true})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.Housnr'), maxLength: 5, priority: true})}
          ${this.number({exclude: exclude, key: buildKey('Zemis.SwissZipcode'), max: 9999, priority: true})}
          ${this.number({exclude: exclude, key: buildKey('Zemis.SwissZipCodeAddon'), max: 99})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.Town'), maxLength: 39, priority: true})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.ForeignZipCode'), maxLength: 10, priority: true})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.ForeignTown'), maxLength: 39, priority: true})}
          ${this.select({exclude: exclude, key: buildKey('Zemis.Country'), items: Enum.country(), priority: true})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.EmployerPhone'), maxLength: 18})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.EmployerFax'), maxLength: 18})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.EmployerEmail'), maxLength: 200})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.EmployerResponsiblePerson'), maxLength: 40})}
          ${this.number({exclude: exclude, key: buildKey('Zemis.DeploymentCommunity'), max: 9999})}
          ${this.select({exclude: exclude, key: buildKey('Zemis.DeploymentCanton'), items: Enum.canton(), priority: true})}
          ${this.select({exclude: exclude, key: buildKey('Zemis.Notificationtype'), items: Enum.notificationType(), priority: true})}
          ${this.number({exclude: exclude, key: buildKey('Zemis.NotificationNr'), max: 999999999999, priority: true})}
          ${this.number({exclude: exclude, key: buildKey('Zemis.NotificationSequenceNr'), max: 999})}
          ${this.number({exclude: exclude, key: buildKey('Zemis.DeploymentZipCode'), max: 999999, priority: true})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.DeploymentTown'), maxLength: 39, priority: true})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.DeploymentAddress'), maxLength: 57, priority: true})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.WorkResponsability'), maxLength: 160})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.Occupation'), maxLength: 160})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.OccupationPosition'), maxLength: 57})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.OccupationReason'), maxLength: 50})}
          ${this.select({exclude: exclude, key: buildKey('Zemis.OccupationAdmission'), items: Enum.occupationAdmission()})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.ContactName'), maxLength: 63})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.ContactStreet'), maxLength: 50})}
          ${this.number({exclude: exclude, key: buildKey('Zemis.ContactZipcode'), max: 999999})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.ContactTown'), maxLength: 50})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.ContactPerson'), maxLength: 40})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.ContactPhone'), maxLength: 13})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.ContactFax'), maxLength: 13})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.ContactEmail'), maxLength: 200})}
          ${this.select({exclude: exclude, key: buildKey('Zemis.DecreeStatus'), items: Enum.decreeStatus()})}
          ${this.date({exclude: exclude, key: buildKey('Zemis.CaptureDate')})}
          ${this.number({exclude: exclude, key: buildKey('Zemis.CaptureUserId'), max: 999999})}
          ${this.date({exclude: exclude, key: buildKey('Zemis.CancellationDate')})}
          ${this.number({exclude: exclude, key: buildKey('Zemis.CancellationUserId'), max: 999999})}
          ${this.date({exclude: exclude, key: buildKey('Zemis.ModificationDate')})}
          ${this.number({exclude: exclude, key: buildKey('Zemis.ModificationUserId'), max: 999999})}
          ${this.entry({content: this.renderWorkPeriods(index), exclude: exclude, key: buildKey('Zemis.Workperiods')})}
          ${this.number({exclude: exclude, key: buildKey('Zemis.AhvNumber'), maxLength: 9999999999999})}
          ${this.date({exclude: exclude, key: buildKey('Zemis.RegistrationDate'), maxLength: 100})}
          ${this.date({exclude: exclude, key: buildKey('Zemis.UpdateDate'), maxLength: 100})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.Salary'), maxLength: 57})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.Comment'), maxLength: 400})}
          ${this.text({exclude: exclude, key: buildKey('Zemis.ReasonText'), maxLength: 400})}
        </div>
        <div class="section">
          <div class="collapsible row" @click="${e => this.rotatePriority(e)}">
            ${this.translate('employees.employee.facts')}
          </div>
          ${this.checkbox({exclude: exclude, key: buildKey('Facts.EmployeeReported'), priority: true, required: true})}
          ${this.checkbox({exclude: exclude, key: buildKey('Facts.EmployeeMet'), priority: true, required: true})}
          ${this.text({exclude: exclude, key: buildKey('Facts.EmployeeStreet'), maxLength: 25, priority: true})}
          ${this.text({exclude: exclude, key: buildKey('Facts.EmployeeStreetAdd'), maxLength: 25})}
          ${this.text({exclude: exclude, key: buildKey('Facts.EmployeeZipCode'), maxLength: 10, priority: true})}
          ${this.text({exclude: exclude, key: buildKey('Facts.EmployeeTown'), maxLength: 39, priority: true})}
          ${this.select({exclude: exclude, key: buildKey('Facts.EmployeeCountry'), items: Enum.country()})}
          ${this.text({exclude: exclude, key: buildKey('Facts.EmployeePhone'), maxLength: 18})}
          ${this.text({exclude: exclude, key: buildKey('Facts.EmployeeAHVN13'), maxLength: 16})}
          ${this.select({exclude: exclude, key: buildKey('Facts.IdType'), items: Enum.idType()})}
          ${this.text({exclude: exclude, key: buildKey('Facts.IdNr'), maxLength: 15, priority: true})}
          ${this.date({exclude: exclude, key: buildKey('Facts.IdValidity')})}
          ${this.date({exclude: exclude, key: buildKey('Facts.DeploymentFrom'), priority: true})}
          ${this.date({exclude: exclude, key: buildKey('Facts.DeploymentTo'), priority: true})}
          ${this.date({exclude: exclude, key: buildKey('Facts.EntryDate'), priority: true})}
          ${this.text({exclude: exclude, key: buildKey('Facts.BorderTown'), maxLength: 39, priority: true})}
          ${this.text({exclude: exclude, key: buildKey('Facts.EmployeeFunction'), maxLength: 40, priority: true})}
          ${this.number({exclude: exclude, key: buildKey('Facts.EmployeeExperience'), max: 99, priority: true})}
          ${this.date({exclude: exclude, key: buildKey('Facts.EmployeeSince')})}
          ${this.text({exclude: exclude, key: buildKey('Facts.Currency'), maxLength: 3, priority: true})}
          ${this.number({exclude: exclude, key: buildKey('Facts.HourWage'), max: 999999.99, digits: 2, priority: true})}
          ${this.number({exclude: exclude, key: buildKey('Facts.MonthlyWage'), max: 999999.99, digits: 2, priority: true})}
          ${this.select({exclude: exclude, key: buildKey('Facts.TimeControl'), items: Enum.timeControl()})}
          ${this.number({exclude: exclude, key: buildKey('Facts.HoursPerDay'), max: 99, priority: true})}
          ${this.number({exclude: exclude, key: buildKey('Facts.HoursPerWeek'), max: 99})}
          ${this.select({exclude: exclude, key: buildKey('Facts.OverTime'), items: Enum.overTime()})}
          ${this.checkbox({exclude: exclude, key: buildKey('Facts.Weekend')})}
          ${this.checkbox({exclude: exclude, key: buildKey('Facts.TravelTime'), priority: true})}
          ${this.checkbox({exclude: exclude, key: buildKey('Facts.Expenses'), priority: true})}
          ${this.number({exclude: exclude, key: buildKey('Facts.ExpLumpSumDay'), max: 999999.99, digits: 2, priority: true})}
          ${this.number({exclude: exclude, key: buildKey('Facts.ExpLumpSumMonth'), max: 999999.99, digits: 2})}
          ${this.checkbox({exclude: exclude, key: buildKey('Facts.Food'), info: true})}
          ${this.checkbox({exclude: exclude, key: buildKey('Facts.Overnight'), info: true})}
          ${this.select({exclude: exclude, key: buildKey('Facts.AdditionalPaycheck'), items: Enum.additionalPaycheck()})}
          ${this.text({exclude: exclude, key: buildKey('Facts.AdditionalCompensation'), maxLength: 1000})}
          ${this.number({exclude: exclude, key: buildKey('Facts.PaiedVacation'), max: 99})}
          ${this.checkbox({exclude: exclude, key: buildKey('Facts.VacationGratuity')})}
          ${this.checkbox({exclude: exclude, key: buildKey('Facts.ChristmasGratuity')})}
          ${this.checkbox({exclude: exclude, key: buildKey('Facts.Application'), info: true})}
          ${this.checkbox({exclude: exclude, key: buildKey('Facts.Certificate'), info: true})}
          ${this.checkbox({exclude: exclude, key: buildKey('Facts.Contract'), info: true})}
        </div>
        <div class="section">
          <div class="collapsible row" @click="${e => this.rotatePriority(e)}">
            ${this.translate('employees.employee.suspicions')}
          </div>
          ${this.checkbox({exclude: exclude, key: buildKey('Suspicions.Meldeverstoss'), priority: true, required: true})}
          ${this.checkbox({exclude: exclude, key: buildKey('Suspicions.Dokumentationspflichtverletzung'), priority: true, required: true})}
          ${this.checkbox({exclude: exclude, key: buildKey('Suspicions.Schwarzarbeit'), priority: true, required: true})}
          ${this.checkbox({exclude: exclude, key: buildKey('Suspicions.CAE'), priority: true, required: true})}
          ${this.checkbox({exclude: exclude, key: buildKey('Suspicions.Scheinselbststaendigkeit'), priority: true, required: true})}
        </div>
      </details>
    `
  }

  removeEmployee = index => {
    this.remove(`#employee-row-${index}`)
  }

  addEmployee = () => {
    this.employees.push({
      EmployeeInfo: {},
      Facts: {},
      Suspicions: {},
      Zemis: {
        WorkPeriods: []
      }
    })
    this.redraw()
  }

  renderWorkPeriods(index) {
    const workPer = this.employees[index].Zemis.WorkPeriods.map((_, periodIndex) => this.renderWorkPeriod(index, periodIndex))
    workPer.push(html`
      <div class="edit">
        <isceco-button icon="plus"
                       variant="tertiary"
                       @click="${_ => this.addWorkPeriod(index)}"
                       text="${this.i18n.translate('label.employees.employee.zemis.workperiods.add')}"
        ></isceco-button>
      </div>
    `)
    return workPer
  }

  renderWorkPeriod = (index, periodIndex) => {
    const buildKey = path => `Employees.Employee.[${index}].Zemis.WorkPeriods.[${periodIndex}].${path}`
    return html`
      <div class="inline" id="employee-${index}-work-period-row-${periodIndex}">
        <isceco-date-input inline
                           ?readonly="${this.read}"
                           name="${buildKey('From')}"
        ></isceco-date-input>
        <isceco-date-input inline
                           ?readonly="${this.read}"
                           name="${buildKey('Until')}"
        ></isceco-date-input>
        <div class="edit" style="flex: 0">
          <isceco-button icon="x"
                         variant="tertiary"
                         @click="${_ => this.removeWorkPeriod(index, periodIndex)}"
          ></isceco-button>
        </div>
      </div>
    `
  }

  removeWorkPeriod = (employee, index) => {
    this.remove(`#employee-${employee}-work-period-row-${index}`)
  }

  addWorkPeriod = employee => {
    this.employees[employee].Zemis.WorkPeriods.push({})
    this.redraw()
  }
}
