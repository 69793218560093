import Render from './Render.js'
import {html} from '@isceco/widget-library2/external/lit'
import Enum from '../Enum/Enum.js'

export default class CompanyRender extends Render {
  get company() {
    return this.metadaten.Companies.Company
  }

  render = () => html`
    <div class="group">
      <div class="header row">
        ${this.i18n.translate('label.companies')}
      </div>
      <div class="section">
        <div class="collapsible row" @click="${e => this.rotatePriority(e)}">
          ${this.i18n.translate('label.companies.company')}
        </div>
        ${this.renderCompany('')}
      </div>
      ${this.renderParentCompany()}
    </div>
  `

  renderParentCompany = () => {
    if (!this.read || this.company.ParentCompany) {
      return html`
        <div class="section">
          <div class="collapsible row" @click="${e => this.rotatePriority(e)}">
            ${this.i18n.translate('label.companies.parentcompany')}
          </div>
          ${this.renderCompany('ParentCompany.')}
        </div>
      `
    } else {
      return html``
    }
  }

  renderCompany = parent => {
    const buildKey = path => `Companies.Company.${parent}${path}`
    return html`
      ${this.renderCompanyId(buildKey, 'IdsInformation', parent)}
      ${this.renderCompanyId(buildKey, 'ISABIdInformation', parent)}
      ${this.text({exclude: parent, key: buildKey('Name'), maxLength: 100, priority: true, required: true})}
      ${this.text({exclude: parent, key: buildKey('AdditionalName'), maxLength: 100})}
      ${this.text({exclude: parent, key: buildKey('Street'), maxLength: 100, priority: true})}
      ${this.text({exclude: parent, key: buildKey('Street2'), maxLength: 100})}
      ${this.text({exclude: parent, key: buildKey('Zip'), maxLength: 100, priority: true})}
      ${this.text({exclude: parent, key: buildKey('City'), maxLength: 100, priority: true, required: true})}
      ${this.select({exclude: parent, key: buildKey('Country'), items: Enum.country(), priority: true, required: true})}
      ${this.text({exclude: parent, key: buildKey('Phone'), maxLength: 30, priority: true})}
      ${this.text({exclude: parent, key: buildKey('Fax'), maxLength: 30})}
      ${this.text({exclude: parent, key: buildKey('Mail'), maxLength: 200, priority: true})}
      ${this.text({exclude: parent, key: buildKey('UID'), maxLength: 30})}
      ${this.text({exclude: parent, key: buildKey('WebAddress'), maxLength: 100})}
      ${this.select({exclude: parent, key: buildKey('CorrespondenceLanguage'), items: Enum.language()})}
    `
  }

  renderCompanyId = (buildKey, key, parent) => {
    const companyIdKey = buildKey(`${key}.IdInformation.CompanyId`)
    const participantIdKey = buildKey(`${key}.IdInformation.ParticipantId`)
    return this.entry({
      content: html`
        <isceco-text-input inline
                           ?readonly="${this.read}"
                           maxlength="100"
                           name="${companyIdKey}"
        ></isceco-text-input>
        <isceco-text-input inline
                           ?readonly="${this.read}"
                           maxlength="100"
                           name="${participantIdKey}"
        ></isceco-text-input>
      `, key: companyIdKey, exclude: parent
    })
  }
}
